import React from 'react';
import './footer.scss';
import DELOITTE_LOGO from '../../assets/logo/DEL_SEC_RGB.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer_container">
                <div className="footer_content">
                    <div className="footer_logo">
                        <img src={DELOITTE_LOGO} alt=""/>
                    </div>
                    <p className="copyright">
                        Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited,
                        a UK private company limited by guarantee ("DTTL"), its network of member firms,
                        and their related entities. DTTL and each of its member firms are legally separate and
                        independent entities. DTTL (also referred to as "Deloitte Global") does not provide
                        services to clients. In the United States, Deloitte refers to one or more of the
                        US member firms of DTTL, their related entities that operate using the "Deloitte" name
                        in the United States and their respective affiliates. Certain services may not be available
                        to attest clients under the rules and regulations of public accounting.
                        Please see <a
                        target="_blank"
                        href="https://www2.deloitte.com/us/en/pages/about-deloitte/articles/about-deloitte.html"
                    >www.deloitte.com/about</a> to learn more about our global network of member firms.
                        <br></br> <br></br>
                        Copyright © 2022 Deloitte Development LLC. All rights reserved.

                    </p>
                </div>
                <div className={"link_container"}>
                    <a target="_blank" rel="noopener"
                       href="https://www2.deloitte.com/us/en/pages/about-deloitte/articles/about-deloitte.html">About
                        Deloitte</a>
                    <span style={{color: "white"}}>|</span>
                    <a target="_blank" rel="noopener"
                       href="https://www2.deloitte.com/us/en/legal/terms-of-use.html"> Terms of Use</a>
                    <span style={{color: "white"}}>|</span>
                    <a target="_blank" rel="noopener" href="https://www2.deloitte.com/us/en/legal/privacy.html">Privacy
                        Notice</a>
                    <span style={{color: "white"}}>|</span>
                    <a target="_blank" rel="noopener" href="https://cookienotice.deloitte.com">Cookies</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import './main.scss';
import transform from '../../assets/main/transform.png';
import sectionLines from '../../assets/main/sectionLines.png';
import solution from '../../assets/main/solution.png';
import React, {useRef} from 'react';
import Scroll from '../scroll/scroll';
import ContactSection from '../contactSection/contactSection';
import MARKETING_VIDEO from '../../assets/main/fs_marketing_video.png';
import PLAY_BTN from '../../assets/main/play_btn.png';
import { BrowserRouter as Router, Link } from 'react-router-dom';

const Main = () => {
    const introRef = useRef();
    const simulateRef = useRef();
    const transformRef = useRef();
    const industrySolutionsRef = useRef();
    const contactUsRef = useRef();


    return (
        <>
        <section className="main">
            <section className="intro section" id="intro" ref={introRef}>
                <span className="catchphrase duborics text_middle">Experiment with the Future</span>
                <div className="marketing_video" id="main_marketing">
                    <div className="marketing_video_wrapper" onClick={() => window.open('https://youtu.be/jWYRJOfPhAI?si=QkBTkijmw1_VzT4D','_blank').focus()}>
                        <img src={PLAY_BTN} alt="Video Play Button" className="play_btn"/>
                        <img src={MARKETING_VIDEO} alt="FutureScape: Marketing Video"></img>
                    </div>
                    <p className="sm-text">Click on the image above to watch the <span className="bold blueGreen">"FutureScape: Predictive Intelligence"</span> video on the Deloitte US YouTube channel.</p>
                </div>
                <span className="textBox light header_4">
                        FutureScape<sup className="TM">TM</sup> is predictive intelligence and AI-powered simulation for massive-scale systems.
                </span>
            </section>

            <section className="two_column_section section" id="simulate" ref={simulateRef}>
                <div className="section_box_title header_1">
                    <div className="borderLeft"></div>
                    <div className="borderTopRight"></div>
                    <div className="borderTopBottom"></div>
                    <div className="borderBottomLeft"></div>
                    <div className="section_box_title_text centerAlign duborics">Simulate Real World Systems</div>
                </div>  
                <div className="main_section_title duborics header_2" id="section_box_mobile">Simulate Real World Systems</div>
                <div className="text">
                        Making decisions about the future isn’t easy, 
                        especially when only historical data— or no data at all —is available. 
                        In a rapidly changing and interconnected world, organizations need a 
                        new approach to making forward-looking decisions: predictive, simulation-powered analytics.
                    <br/><br/>
                        FutureScape transforms how organizations make tough decisions
                        about their complex, interconnected systems by providing 
                        simulation-powered insights and the ability to evaluate 
                        multiple scenarios quickly and iteratively to effectively 
                        prepare for the future.
                </div>
            </section>

            <section className="two_column_section section" id="transform" ref={transformRef}>
                <div>
                    <div className="main_section_title duborics header_2">
                        TRANSFORM HOW YOU MAKE DECISIONS
                    </div>
                    <div className="text">FutureScape creates digital replicas of massive-scale, 
                        real-world systems, and tests ways to improve them. 
                        It allows organizations to analyze the ripple effects 
                        of potential decisions or disruptions safely and 
                        systematically. Our fast, high-fidelity simulations 
                        provide organizations with powerful, predictive insights 
                        to inform agile strategy development, scenario planning 
                        and preparedness, impact analysis, and much more.
                    </div>
                    <Link to="/howItWorks">
                        <button className="section_btn main text">How it works</button>
                    </Link>
                </div>
                <img src={transform} alt="transform" id="transform_img"></img>
            </section>

            <section className="one_column_section section" id="industrySolutions" ref={industrySolutionsRef}>
                <div className="middle auto">
                    <div className="main_section_title duborics header_2">
                        INDUSTRY-TAILORED SOLUTIONS
                    </div>
                    <div className="text">
                        FutureScape powers a suite of solutions tailored to inform industry-specific
                        decision-making. It can help lower barriers to simulation-powered analysis and provides
                        a fast, low-risk way to evaluate strategies and scenarios that couldn’t 
                        otherwise be tested in the real world.
                    <br/><br/>
                        Join our clients across industries already using FutureScape to shape the 
                        complex systems that their organizations and customers rely on.
                    </div>
                    <img src={solution} alt="" className="solution_image"></img>
                    <Link to="/solutions">
                        <button className="section_btn main text">Solutions</button>
                    </Link>
                </div>
            </section>
            <div className="sectionLine_wrapper">
                <div className="sectionLines">
                    <img src={sectionLines} alt=""></img>
                </div>
            </div>
        </section>
        <section id="contactUs" ref={contactUsRef}>
            <ContactSection />
        </section>
        <Scroll
            scrollSection = {[introRef, simulateRef, transformRef, industrySolutionsRef, contactUsRef]}
            />
        </>
    )
}
export default Main;